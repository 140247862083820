import React from 'react'
import styles from './Button.module.css'

function Button(props) {
    return (
        <div className={styles.actions}>
            <button onClick={props.onClick} disabled={props.disabled}>{props.children}</button>
        </div>
    )
}

export default Button