import React from "react";
import "../../App.css";
import ErrorModal from "../../Components/UI/ErrorModal";

function ErrorPage() {
  return (
    <div className="body">
      <ErrorModal />
    </div>
  );
}

export default ErrorPage;
