import React, { useEffect, useRef, useState } from "react";
import styles from "./Contract.module.css";
import image from "../../asset/images/car.png";
import VehicleInformation2 from "./VehicleInformation2";
import Papers from "./Papers";
import Button from "../../Components/interface/Button";
import UserApi from "../../utils/api/UserApi";
import PapersDocument from "./PapersDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Images from "../../utils/CarImages";
import ContractApi from "../../utils/api/ContractApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddedVehicles from "../../Components/vehicle/AddedVehicles";

function Contract(props) {
  const [userData, setUserData] = useState(null);
  const [papersData, setPapersData] = useState(null);
  const [contractDet, setContractDet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invalidPapers, setInvalidPapers] = useState(null);
  const [vehicleIndex, setVehicleIndex] = useState(0);
  const [acceptedContract, setAcceptedContract] = useState(false);

  const currentDate = new Date();

  const formattedDate = currentDate.toDateString();

  const componentRef = useRef();

  const item = localStorage.getItem("userData");

  const parsedItem = JSON.parse(item);

  const plate_no = parsedItem?.userData.vehicles[vehicleIndex].plate_no;

  useEffect(() => {
    const fetchBasicDataHandler = async () => {
      try {
        const res = await UserApi.getBasicData({
          plate_no: plate_no,
        });
        const getContractData = await ContractApi.getContract({
          plate_no: plate_no,
        });
        setUserData(res.data[0]);
        if (getContractData.data.length > 0) {
          setContractDet(getContractData.data);
          setAcceptedContract(true);
        } else {
          setAcceptedContract(false);
        }
      } catch (err) {
        console.log(err.response);
      }
    };
    fetchBasicDataHandler();
  }, [plate_no]);

  const acceptContractHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await ContractApi.postContract({
        plate_no: plate_no,
      });
      toast.success(
        `Contract accepted. Click the 'Download Papers Agreement' button to download agreement`,
        {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      const getContractData = await ContractApi.getContract({
        plate_no: plate_no,
      });
      setLoading(false);
      setContractDet(getContractData.data);
      setAcceptedContract(true);
      return res.data;
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  let carImage = Images?.find(
    (data) =>
      data.name?.toUpperCase() ===
      `${
        papersData?.insurance[0]?.year
      }-${papersData?.insurance[0]?.make?.toUpperCase()}-${papersData?.insurance[0]?.model?.toUpperCase()}`
  );

  return (
    <section className={styles.buyDongle}>
      <ToastContainer />
      <div className={styles.buyDongle__body}>
        <div className={styles.buyDongle__bodyInner}>
          <div>
            <div className={styles.carType}>
              <div className={styles.buyDongle__wrapper}>
                <img src={carImage?.image || image} alt="dongle" />
              </div>
            </div>
          </div>
          <AddedVehicles
            number={props.number}
            setVehicleIndex={setVehicleIndex}
          />
          <VehicleInformation2 userData={userData} papersData={papersData} />
          <Papers
            setInvalidPapers={setInvalidPapers}
            setPapersData={setPapersData}
            plate_no={plate_no}
          />

          <div className={styles.vehicleDetails_2_head} ref={componentRef}>
            <div className={styles.contract_card}>
              <h1>Review Agreement, Terms & Conditions</h1>
              {!acceptedContract && (
                <div onClick={acceptContractHandler}>
                  {!loading && <Button>Click To Agree To Contract</Button>}
                  {loading && <Button>Loading...</Button>}
                </div>
              )}

              {acceptedContract && !invalidPapers && (
                <PDFDownloadLink
                  document={
                    <PapersDocument
                      formattedDate={formattedDate}
                      papersData={papersData}
                      plate_no={plate_no}
                      contractDet={contractDet}
                    />
                  }
                  fileName="Motormata Papers Contract"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <div>
                        <Button>Download Papers Agreement</Button>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              )}

              {invalidPapers && (
                <p>
                  Unfortunately, you have to clear any pending fines and renew
                  expired papers before we can issue you an agreement.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contract;
