import axios from "axios";

const BASE_URL = process.env.REACT_APP__BASE_URL;

const UserApi = {
  getBasicData: (payload) => {
    const res = axios({
      url: `${BASE_URL}/premiumpapers/app/data/${payload.plate_no}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getPaperData: (payload) => {
    const res = axios({
      url: `${BASE_URL}/premiumpapers/app/papers/${payload.plate_no}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};
export default UserApi;
