import React, { useEffect, useState } from "react";
import styles from "./AddedVehicles.module.css";
import { Link } from "react-router-dom";
import UserApi from "../../utils/api/UserApi";
import Images from "../../utils/CarImages";

const AddedVehicles = (props) => {
  const [vehicles, setVehicles] = useState(null);
  const [vehicleArr, setVehicleArr] = useState([]);

  const { setVehicleIndex } = props;

  useEffect(() => {
    const item = localStorage.getItem("userData");

    const parsedItem = JSON.parse(item);

    const vehicleArray = parsedItem?.userData?.vehicles;
    setVehicleArr(vehicleArray);
  }, []);

  useEffect(() => {
    // Function to fetch all vehicles from thier plate numbers
    const fetchVehicles = async () => {
      // Fetch all vehicle data at once
      const vehicles_plate_no = await Promise?.all(
        vehicleArr?.map(async (data) => {
          try {
            const res = await UserApi.getPaperData({
              plate_no: data.plate_no,
            });
            // Get images of all vehicles from the array
            let carImage = Images?.find(
              (data) =>
                data.name?.toUpperCase() ===
                `${
                  res.data?.insurance[0]?.year
                }-${res.data?.insurance[0]?.make?.toUpperCase()}-${res.data?.insurance[0]?.model?.toUpperCase()}`
            );
            return carImage.image;
          } catch (err) {
            console.log(err.response);
            return null;
          }
        })
      );
      setVehicles(vehicles_plate_no);
    };
    fetchVehicles();
  }, [vehicleArr]);

  // set Index of vehicle user clicks on
  const currVehicleHandler = (index) => {
    setVehicleIndex(index);
  };

  return (
    <div className={styles.vehicleDetails_2}>
      <div className={styles.vehicleDetails_2_head}>
        <p>Added vehicles</p>
      </div>
      <div className={styles.vehicle_images}>
        <div className={styles.vehicleList_wrapper}>
          {vehicles?.map((cars, index) => (
            <div
              key={cars}
              className={styles.vehicleList_wrapper_inner}
              onClick={() => currVehicleHandler(index)}
            >
              <div className={styles.vehicleList_wrapper_inner_image}>
                <img src={cars} alt="dongle" />
              </div>
            </div>
          ))}
        </div>

        <div className={styles.add_vehicles}>
          <Link to={`/all-vehices/${props.number}`}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <rect y="0.5" width="24" height="24" rx="12" fill="#088457" />
                <mask id="path-2-inside-1_8201_134546" fill="white">
                  <path d="M12.75 11.75V6.5H11.25V11.75H6V13.25H11.25V18.5H12.75V13.25H18V11.75H12.75Z" />
                </mask>
                <path
                  d="M12.75 11.75V6.5H11.25V11.75H6V13.25H11.25V18.5H12.75V13.25H18V11.75H12.75Z"
                  fill="white"
                />
                <path
                  d="M12.75 11.75H8.75V15.75H12.75V11.75ZM12.75 6.5H16.75V2.5H12.75V6.5ZM11.25 6.5V2.5H7.25V6.5H11.25ZM11.25 11.75V15.75H15.25V11.75H11.25ZM6 11.75V7.75H2V11.75H6ZM6 13.25H2V17.25H6V13.25ZM11.25 13.25H15.25V9.25H11.25V13.25ZM11.25 18.5H7.25V22.5H11.25V18.5ZM12.75 18.5V22.5H16.75V18.5H12.75ZM12.75 13.25V9.25H8.75V13.25H12.75ZM18 13.25V17.25H22V13.25H18ZM18 11.75H22V7.75H18V11.75ZM16.75 11.75V6.5H8.75V11.75H16.75ZM12.75 2.5H11.25V10.5H12.75V2.5ZM7.25 6.5V11.75H15.25V6.5H7.25ZM11.25 7.75H6V15.75H11.25V7.75ZM2 11.75V13.25H10V11.75H2ZM6 17.25H11.25V9.25H6V17.25ZM7.25 13.25V18.5H15.25V13.25H7.25ZM11.25 22.5H12.75V14.5H11.25V22.5ZM16.75 18.5V13.25H8.75V18.5H16.75ZM12.75 17.25H18V9.25H12.75V17.25ZM22 13.25V11.75H14V13.25H22ZM18 7.75H12.75V15.75H18V7.75Z"
                  fill="#D7FBDB"
                  mask="url(#path-2-inside-1_8201_134546)"
                />
              </g>
            </svg>
            <p>View all</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AddedVehicles;
