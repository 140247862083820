import axios from "axios";

const BASE_URL = process.env.REACT_APP__BASE_URL;

const Authentication = {
  otpReqHandler: (payload) => {
    const res = axios({
      url: `${BASE_URL}/premiumpapers/app/otp/request`,
      method: "POST",
      data: JSON.stringify({
        phone_no: payload.phone_no,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  otpVerificationHandler: (payload) => {
    const res = axios({
      url: `${BASE_URL}/premiumpapers/app/otp/validation`,
      method: "POST",
      data: JSON.stringify({
        phone_no: payload.phone_no,
        otp: payload.otp,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};
export default Authentication;
