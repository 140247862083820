import "./App.css";
import { Navigate, Route, Routes } from "react-router";
// import Contract from "./Pages/contract/Contract";
import Login from "./Pages/auth/Login";
import { useEffect, useState } from "react";
import Success from "./Components/UI/Success";
// import ContractParent from "./Pages/contract/ContractParent";
import Contract from "./Pages/contract/Contract";
import ErrorPage from "./Pages/404/ErrorPage";
import AllCars from "./Pages/contract/AllCars";

function App() {
  const [showModal, setShowModal] = useState(false);

  const currentURL = window.location.href;

  // Extract the number from the URL using split() method
  const parts = currentURL.split("/");
  const number = parts[parts.length - 1];

  const handleResize = () => {
    setShowModal(window.innerWidth > 920);
  };

  useEffect(() => {
    // Check screen size on initial mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isLocalStorageKeyExists = localStorage.getItem("userData") !== null;

  return (
    <div className="App">
      {showModal && (
        <div className="body">
          <Success />
        </div>
      )}
      {!showModal && (
        <div>
          <Routes>
            <Route
              path="/:number"
              element={
                (!isLocalStorageKeyExists && <Login />) ||
                (isLocalStorageKeyExists && <Navigate to={`/home/${number}`} />)
              }
            />
            <Route
              path="/home/:number"
              element={
                (isLocalStorageKeyExists && <Contract number={number} />) ||
                (!isLocalStorageKeyExists && <Navigate to={`/${number}`} />)
              }
            />
            <Route
              path="/all-vehices/:number"
              element={
                (isLocalStorageKeyExists && <AllCars number={number} />) ||
                (!isLocalStorageKeyExists && <Navigate to={`/${number}`} />)
              }
            />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      )}
    </div>
  );
}

export default App;
