import React, { useState, useEffect } from "react";
import styles from "./Login.module.css";
import Logo from "../../Components/interface/Logo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OTPInput from "otp-input-react";
import Button from "../../Components/interface/Button";
import Authentication from "../../utils/api/Authentication";
import { useNavigate, useParams } from "react-router";

const initialBoolenValues = {
  formIsValid: false,
};

function Login() {
  const [boolen, setBoolen] = useState(initialBoolenValues);
  const [loading, setLoading] = useState(false);
  const [OTP, setOTP] = useState("");

  const { number } = useParams();

  const navigate = useNavigate();
  //Form Validation//
  useEffect(() => {
    if (OTP.trim().length >= 6) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
  }, [OTP]);

  useEffect(() => {
    const fetchBasicDataHandler = async () => {
      try {
        const res = await Authentication.otpReqHandler({
          phone_no: number,
        });
        toast.success(`An OTP has been sent to your number`, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setLoading(false);
        return res.data;
      } catch (err) {
        setLoading(false);
        toast.error(err.response.data, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    };
    fetchBasicDataHandler();
  }, [number]);

  useEffect(() => {
    const fetchBasicDataHandler = async () => {
      if (OTP.length === 6) {
        try {
          setLoading(true);
          const res = await Authentication.otpVerificationHandler({
            phone_no: number,
            otp: OTP,
          });
          const userData = res.data;
          const dataToStore = { userData };
          localStorage.setItem("userData", JSON.stringify(dataToStore));
          navigate(`/home/${number}`);
          window.location.reload();
        } catch (err) {
          setLoading(false);
          toast.error(err.response.data, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    };
    fetchBasicDataHandler();
  }, [OTP, number, navigate]);

  let resendTimerHandler = async () => {
    try {
      setLoading(true);
      const res = await Authentication.otpReqHandler({
        phone_no: number,
      });
      console.log(res.data);
      toast.success(`An otp has been sent to your number`, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Login</p>
        </div>
      </div>
      <div className={styles.form}>
        <form>
          <div className={styles.form__inner}>
            <label htmlFor="code">Enter the code you received</label>
            <OTPInput
              value={OTP}
              OTPLength={6}
              onChange={setOTP}
              otpType="number"
              inputStyles={{
                width: "2rem",
                height: "2.9rem",
                margin: "0 0.1rem",
                borderRadius: 4,
              }}
            />
          </div>
          {!loading && <Button disabled={!boolen.formIsValid}>Login</Button>}
          {loading && <Button disabled={boolen.formIsValid}>Loading...</Button>}
        </form>

        <div className={styles.login__options}>
          <p>
            Didn’t receive the code?{" "}
            <span onClick={resendTimerHandler}>Resend</span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Login;
