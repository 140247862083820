import React from "react";
import styles from "./SuccessModal.module.css";

function ErrorModal() {
  return (
    <div className={styles.card}>
      <div className={styles.success}>
        <svg
          width="49"
          height="48"
          viewBox="0 0 49 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" width="48" height="48" rx="24" fill="#008069" />
          <mask id="path-2-inside-1_6821_132726" fill="white">
            <path d="M24.4998 41.1431C33.9675 41.1431 41.6426 33.468 41.6426 24.0003C41.6426 14.5325 33.9675 6.85742 24.4998 6.85742C15.0321 6.85742 7.35693 14.5325 7.35693 24.0003C7.35693 33.468 15.0321 41.1431 24.4998 41.1431Z" />
            <path d="M26.2141 30.8574C26.2141 31.8042 25.4466 32.5717 24.4998 32.5717C23.553 32.5717 22.7855 31.8042 22.7855 30.8574C22.7855 29.9106 23.553 29.1431 24.4998 29.1431C25.4466 29.1431 26.2141 29.9106 26.2141 30.8574Z" />
          </mask>
          <path
            d="M25.4998 17.1431C25.4998 16.5909 25.0521 16.1431 24.4998 16.1431C23.9475 16.1431 23.4998 16.5909 23.4998 17.1431H25.4998ZM23.4998 24.0003C23.4998 24.5526 23.9475 25.0003 24.4998 25.0003C25.0521 25.0003 25.4998 24.5526 25.4998 24.0003H23.4998ZM23.4998 17.1431V24.0003H25.4998V17.1431H23.4998ZM39.6426 24.0003C39.6426 32.3634 32.863 39.1431 24.4998 39.1431V43.1431C35.0721 43.1431 43.6426 34.5726 43.6426 24.0003H39.6426ZM24.4998 39.1431C16.1366 39.1431 9.35693 32.3634 9.35693 24.0003H5.35693C5.35693 34.5726 13.9275 43.1431 24.4998 43.1431V39.1431ZM9.35693 24.0003C9.35693 15.6371 16.1366 8.85742 24.4998 8.85742V4.85742C13.9275 4.85742 5.35693 13.428 5.35693 24.0003H9.35693ZM24.4998 8.85742C32.863 8.85742 39.6426 15.6371 39.6426 24.0003H43.6426C43.6426 13.428 35.0721 4.85742 24.4998 4.85742V8.85742ZM24.2141 30.8574C24.2141 30.6996 24.342 30.5717 24.4998 30.5717V34.5717C26.5511 34.5717 28.2141 32.9088 28.2141 30.8574H24.2141ZM24.4998 30.5717C24.6576 30.5717 24.7855 30.6996 24.7855 30.8574H20.7855C20.7855 32.9088 22.4484 34.5717 24.4998 34.5717V30.5717ZM24.7855 30.8574C24.7855 31.0152 24.6576 31.1431 24.4998 31.1431V27.1431C22.4484 27.1431 20.7855 28.8061 20.7855 30.8574H24.7855ZM24.4998 31.1431C24.342 31.1431 24.2141 31.0152 24.2141 30.8574H28.2141C28.2141 28.8061 26.5511 27.1431 24.4998 27.1431V31.1431Z"
            fill="white"
            mask="url(#path-2-inside-1_6821_132726)"
          />
        </svg>

        <h1>404 Error!</h1>
      </div>
      <div className={styles.success}>
        <p>
          This page you are on cannot be found. Click on the link sent to you by
          MOTORMATA to view the page.
        </p>
      </div>
    </div>
  );
}

export default ErrorModal;
