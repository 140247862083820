import axios from "axios";

const BASE_URL = process.env.REACT_APP__BASE_URL;
const ContractApi = {
  postContract: (payload) => {
    const res = axios({
      url: `${BASE_URL}/premiumpapers/app/contract/create`,
      method: "POST",
      data: JSON.stringify({
        plate_no: payload.plate_no,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getContract: (payload) => {
    const res = axios({
      url: `${BASE_URL}/premiumpapers/app/contract/info/${payload.plate_no}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export default ContractApi;
