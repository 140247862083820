import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import MyCustomFont from "../../utils/fonts/FontsFree-Net-Circular-Std-Book-2.ttf";

import MyBoldCustomFont from "../../utils/fonts/FontsFree-Net-Circular-Std-Bold.ttf";

import signatureImage from "../../asset/images/signature.png";

Font.register({ family: "CustomFont", src: MyCustomFont });

Font.register({ family: "BoldCustomFont", src: MyBoldCustomFont });

function PapersDocument(props) {
  const styles = StyleSheet.create({
    vehicleDetails_2_head: {
      marginLeft: 60,
      marginRight: 60,
    },

    h1: {
      textAlign: "center",
      fontSize: 20,
      margin: 10,
      marginTop: 30,
      fontWeight: 800,
      fontFamily: "BoldCustomFont",
    },

    p: {
      fontSize: 11,
      margin: 10,
      marginTop: 2,
      fontFamily: "CustomFont",
    },

    p1: {
      fontSize: 11,
      margin: 10,
      marginBottom: 10,
      marginTop: 2,
      fontFamily: "CustomFont",
    },

    heading: {
      fontSize: 13,
      marginLeft: 10,
      fontWeight: 800,
      fontFamily: "BoldCustomFont",
    },

    heading2: {
      fontSize: 13,
      marginLeft: 10,
      fontFamily: "BoldCustomFont",
    },

    heading3: {
      fontSize: 14,
      marginLeft: 10,
      marginBottom: 8,
      fontFamily: "BoldCustomFont",
    },

    total: {
      fontSize: 11,
      fontWeight: 800,
      fontFamily: "BoldCustomFont",
    },

    table: {
      display: "table",
      width: "60%",
      borderStyle: "solid",
      borderWidth: 1,
      marginLeft: 10,
      marginBottom: 10,
      borderColor: "#bfbfbf",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      marginBottom: 5,
      fontSize: 12,
      width: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#bfbfbf",
      padding: 5,
    },
    foot: {
      marginBottom: 10,
      fontSize: 11,
      fontFamily: "CustomFont",
    },
    footer: {
      marginLeft: 10,
      marginTop: 20,
    },
    signatureContainer: {
      textAlign: "right",
      marginTop: 20,
    },
    signatureImage: {
      width: 130,
      height: 50,
    },
  });

  return (
    <Document>
      <Page>
        <View style={styles.vehicleDetails_2_head}>
          <View style={styles.contract_card}>
            <Text style={styles.h1}>TRAFFIC FINE AGREEMENT</Text>
            <Text style={styles.p}>
              This Agreement is executed between Motormata Auto Services
              Limited, with address at Plot 4, Block B8, Jubilee/CMD road,
              beside Esporta Lounge & Suites, Magodo GRA extension, Lagos
              (hereinafter referred to as "Motormata"), and{" "}
              {props?.userData?.name}, who is the certified owner of a{" "}
              {props?.papersData?.insurance[0]?.year}{" "}
              {props?.papersData?.insurance[0]?.make?.toUpperCase()}{" "}
              {props?.papersData?.insurance[0]?.model?.toUpperCase()}, with
              registration number {props?.plate_no} (hereinafter referred to as
              the “Client”).
            </Text>

            <Text style={styles.p}>
              In consideration of the mutual promises and covenants in this
              Agreement, of which the receipt and sufficiency are hereby
              acknowledged, the Parties agree to the terms as follows:
            </Text>

            <View>
              <Text style={styles.heading}>Service Coverage</Text>
              <Text style={styles.p}>
                Motormata agrees to monitor the expiry dates of the following
                vehicle documents i.e. Insurance, vehicle license and road
                worthiness of the Client’s{" "}
                {props?.papersData?.insurance[0]?.year}{" "}
                {props?.papersData?.insurance[0]?.make?.toUpperCase()}{" "}
                {props?.papersData?.insurance[0]?.model?.toUpperCase()}, with
                registration number {props?.plate_no} and agrees to pay for any
                traffic fine incurred for driving the vehicle with expired
                papers in Lagos state.
              </Text>
            </View>

            <View>
              <Text style={styles.heading}>Exclusions</Text>
              <Text style={styles.p}>
                This Agreement does not provide coverage for other traffic fines
                that are not related to driving with expired vehicle papers.
              </Text>
            </View>

            <View>
              <Text style={styles.heading}>Limits of Liability</Text>
              <Text style={styles.p}>
                Motormata's maximum liability for any one occurrence covered
                under this Agreement shall not exceed the limit of liability in
                the amount of #60,000.
              </Text>
              <Text style={styles.p}>
                Motormata has no obligation to pay any damages, losses, or
                expenses that the Client may incur due to any possible damage to
                the vehicle stated above.{" "}
              </Text>
            </View>

            <View>
              <Text style={styles.heading}>Term</Text>
              <Text style={styles.p}>
                The Agreement shall be effective as of{" "}
                {props?.contractDet[0]?.issue_date} and shall remain in force
                until {props?.contractDet[0]?.expiry_date} unless terminated
                earlier under the terms of this Agreement.
              </Text>
              <Text style={styles.p}>
                The Agreement will be renewed upon the renewal of the
                subscription and in mutual agreement of the Motormata and the
                Client, subject to any changes in renewal fees, deductibles, or
                other terms and conditions that may be negotiated at the time of
                renewal.
              </Text>

              <Text style={styles.p}>
                Either Party may terminate this Agreement by giving written
                notice to the other Party per the notice provisions set forth in
                this Agreement.
              </Text>
            </View>

            <View>
              <Text style={styles.heading}>Fine Payment Process</Text>
              <Text style={styles.p}>
                If the Client gets fined in Lagos state while this contract is
                still active, the Client agrees to give Motormata 21 days to
                dispute such a fine with the respective law enforcement agency.
                Failure to successfully reverse the fine within the set period
                means Motormata will pay such fines at the end of the notice.
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page>
        <View style={styles.vehicleDetails_2_head}>
          <View style={styles.contract_card}>
            <Text style={styles.h1}>TRAFFIC FINE AGREEMENT</Text>
            <view>
              <Text style={styles.heading}>Renewal Process</Text>
              <Text style={styles.p}>
                The Client agrees to pay a total of #27,000 to renew his/her
                vehicle papers which included;
              </Text>
            </view>
          </View>

          <View style={styles.table}>
            {/* Table Header */}
            {/* Table Rows */}
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text>Vehicle License</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>4,500</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text>Roadworthiness</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>6,500</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text>Third-Party Insurance</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>15,000</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text>Delivery</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>1,000</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.total}>Total</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.total}>27,000</Text>
              </View>
            </View>
          </View>

          <View style={styles.contract_card}>
            <Text style={styles.p}>
              The renewal shall be due and payable on the due date stated in the
              policy, which shall be no later than{" "}
              {props?.contractDet[0]?.expiry_date}.
            </Text>

            <View>
              <Text style={styles.heading}>Termination</Text>
              <Text style={styles.p}>
                Motormata may terminate this Agreement for any of the following
                reasons:
              </Text>
            </View>

            <View>
              <Text style={styles.heading}>Non-payment of renewal fees:</Text>
              <Text style={styles.p}>
                If the Client fails to pay the renewal fees due under this
                agreement to the company before the{" "}
                {props?.contractDet[0]?.expiry_date}, Motormata will terminate
                the policy upon providing written notice to the Client.
              </Text>
              <Text style={styles.p}>
                The Client may terminate this Agreement for any reason upon
                providing 3 days prior written notice to the Motormata.
              </Text>
            </View>

            <View>
              <Text style={styles.heading3}>Miscellaneous</Text>
            </View>

            <View>
              <Text style={styles.heading2}>Amendments:</Text>
              <Text style={styles.p1}>
                This Agreement may not be amended or modified except in writing
                signed by both parties.
              </Text>
            </View>

            <View>
              <Text style={styles.heading2}>Governing Law:</Text>
              <Text style={styles.p1}>
                This Agreement shall be governed by and construed by the laws of
                Lagos State, Nigeria.{" "}
              </Text>
            </View>

            <View>
              <Text style={styles.heading}>Signed</Text>
            </View>
            <View style={styles.signatureContainer}>
              <Image src={signatureImage} style={styles.signatureImage} />
            </View>
            <View style={styles.footer}>
              <Text style={styles.foot}>Temitope Fashae</Text>
              <Text style={styles.foot}>Managing Director</Text>
              <Text style={styles.foot}>{props.formattedDate}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PapersDocument;
