import React from "react";
import styles from "./VehicleInformation.module.css";
import photo from "../../asset/images/Avatar.png";

function VehicleInformation2(props) {
  return (
    <div className={styles.vehicleDetails_2}>
      <div className={styles.vehicleDetails_2_head}>
        <p>Vehicle Infomation</p>
      </div>
      <div className={styles.card_outter}>
        <div className={styles.card}>
          <div className={styles.card_personal_info}>
            <div className={styles.card_personal_info_inner}>
              <div className={styles.image_outer}>
                <div className={styles.image}>
                  <div className={styles.image_wrapper}>
                    <img src={photo} alt="user" />
                  </div>
                </div>
                <div className={styles.user_info}>
                  <h4>{props?.userData?.name}</h4>
                  <p>{props?.userData?.phone_no}</p>
                </div>
              </div>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="8" cy="8" r="8" fill="#0DB226" />
              </svg>
            </div>
          </div>
          <div className={styles.car_details}>
            <div className={styles.car_details_inner}>
              <div className={styles.car_details_info}>
                <div className={styles.car_details_row}>
                  <svg
                    width="5"
                    height="34"
                    viewBox="0 0 5 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="5" height="34" rx="2.5" fill="#5D5FEF" />
                  </svg>
                  <div>
                    <p>Vehicle Make</p>
                    <h3>{props?.papersData?.insurance[0]?.make}</h3>
                  </div>
                </div>

                <div className={styles.car_details_row}>
                  <svg
                    width="5"
                    height="34"
                    viewBox="0 0 5 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="5" height="34" rx="2.5" fill="#0DB226" />
                  </svg>

                  <div>
                    <p>VIN {props?.papersData?.insurance[0]?.vin}</p>
                    <h3>{props?.userData?.plate_no}</h3>
                  </div>
                </div>
              </div>

              <div className={styles.car_details_info}>
                <div className={styles.car_details_row}>
                  <svg
                    width="5"
                    height="34"
                    viewBox="0 0 5 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="5" height="34" rx="2.5" fill="#BE52F2" />
                  </svg>

                  <div>
                    <p>Vehicle Model</p>
                    <h3>{props?.papersData?.insurance[0]?.model}</h3>
                  </div>
                </div>

                <div className={styles.car_details_row}>
                  <svg
                    width="5"
                    height="34"
                    viewBox="0 0 5 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="5" height="34" rx="2.5" fill="#FF9900" />
                  </svg>

                  <div>
                    <p>Vehicle Year</p>
                    <h3>{props?.papersData?.insurance[0]?.year}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleInformation2;
