import React, { useEffect, useState } from "react";
import styles from "./VehicleInformation.module.css";
import UserApi from "../../utils/api/UserApi";

function Papers(props) {
  const [userData, setUserData] = useState(null);
  const [licenseStatus, setLicenseStatus] = useState("");
  const [roadWorthinessStatus, setRoadWorthinessStatus] = useState("");
  const [insuranceStatus, setInsuranceStatus] = useState("");

  const { setPapersData, plate_no, setInvalidPapers } = props;

  const checkExpiryStatus = (expiryDate) => {
    const currentDate = new Date();
    const expiry = new Date(expiryDate);
    const timeDiff = expiry - currentDate;
    const daysToExpiry = timeDiff / (1000 * 60 * 60 * 24);

    if (daysToExpiry > 30) {
      return "ACTIVE-#D7FBDB$#008069";
    } else if (daysToExpiry > 0 && daysToExpiry < 30) {
      return "ACTIVE-#FFF5CC$#F90";
    } else {
      return "EXPIRED-#FFDFD7$#FD4D1E";
    }
  };

  useEffect(() => {
    const fetchBasicDataHandler = async () => {
      try {
        const res = await UserApi.getPaperData({
          plate_no: plate_no,
        });
        setPapersData(res.data);
        setUserData(res.data);
      } catch (err) {
        console.log(err.response);
      }
    };
    fetchBasicDataHandler();
  }, [plate_no, setPapersData]);

  //check availability of contract
  useEffect(() => {
    if (
      licenseStatus.includes("EXPIRED") ||
      roadWorthinessStatus.includes("EXPIRED") ||
      insuranceStatus.includes("EXPIRED") ||
      userData?.fine !== "No Record Found"
    ) {
      setInvalidPapers(true);
    }
  }, [
    licenseStatus,
    roadWorthinessStatus,
    insuranceStatus,
    setInvalidPapers,
    userData?.fine,
  ]);

  useEffect(() => {
    setLicenseStatus(
      checkExpiryStatus(userData?.vehicle_license[0]?.expiry_date)
    );
    setRoadWorthinessStatus(checkExpiryStatus(userData?.road[0]?.expiry_date));
    setInsuranceStatus(checkExpiryStatus(userData?.insurance[0]?.expiry_date));
  }, [userData?.vehicle_license, userData?.road, userData?.insurance]);

  const splitStringAtPunctuation = (str) => {
    const punctuationIndex = str.search(/[.,/!$%^&*;:{}=_`~()-]/);

    // Split the string at the punctuation index (including the punctuation)
    const result =
      punctuationIndex !== -1 ? str.substring(0, punctuationIndex) : str;

    return result.trim(); // Remove leading/trailing whitespaces
  };

  const getColorCode = (str) => {
    const punctuationIndex = str.search(/[.,/!$%^&*;:{}=\-_`~()]/);

    const strIndex = str.search(/[$]/);

    // Split the string at the punctuation index (including the punctuation)
    const result =
      punctuationIndex !== -1
        ? str.substring(punctuationIndex + 1, strIndex)
        : str;

    return result.trim(); // Remove leading/trailing whitespaces
  };

  const getTextColor = (str) => {
    const punctuationIndex = str.search(/[.,/!$%^&*;:{}=_`~()]/);

    const strIndex = str.length;

    // Split the string at the punctuation index (including the punctuation)
    const result =
      punctuationIndex !== -1
        ? str.substring(punctuationIndex + 1, strIndex)
        : str;

    return result.trim(); // Remove leading/trailing whitespaces
  };

  return (
    <div className={styles.vehicleDetails_2}>
      <div className={styles.vehicleDetails_2_head}>
        <p>Vehicle Papers</p>
      </div>
      <div className={styles.papers_card}>
        <div className={styles.papers_card_upper}>
          <div>
            <p>Vehicle License</p>
          </div>
          <div>
            <p style={{ color: getTextColor(licenseStatus) }}>
              {splitStringAtPunctuation(licenseStatus)}
            </p>
          </div>
        </div>
        <div
          className={styles.papers_card_lower}
          style={{ backgroundColor: getColorCode(licenseStatus) }}
        >
          <div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.42 7.5L16 11H8L9.42 7.5H14.42ZM9 12C8.45 12 8 12.45 8 13C8 13.55 8.45 14 9 14C9.55 14 10 13.55 10 13C10 12.45 9.55 12 9 12ZM15 12C14.45 12 14 12.45 14 13C14 13.55 14.45 14 15 14C15.55 14 16 13.55 16 13C16 12.45 15.55 12 15 12ZM21 5V11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5L12 1L21 5ZM18 12L15.84 7C15.64 6.42 15.08 6 14.42 6H9.42C8.76 6 8.2 6.42 8 7L6 12V16C6 16.55 6.45 17 7 17H8C8.55 17 9 16.55 9 16V15H15V16C15 16.55 15.45 17 16 17H17C17.55 17 18 16.55 18 16V12Z"
                fill={getTextColor(licenseStatus)}
              />
            </svg>

            <p>Vehicle License</p>
          </div>
          <div>
            <p>{userData?.vehicle_license[0]?.expiry_date}</p>
            <p>Expiry date</p>
          </div>
        </div>
      </div>

      <div className={styles.papers_card}>
        <div className={styles.papers_card_upper}>
          <div>
            <p>Insurance</p>
          </div>
          <div>
            <p style={{ color: getTextColor(insuranceStatus) }}>
              {splitStringAtPunctuation(insuranceStatus)}
            </p>
          </div>
        </div>
        <div
          className={styles.papers_card_lower}
          style={{ backgroundColor: getColorCode(insuranceStatus) }}
        >
          <div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.42 7.5L16 11H8L9.42 7.5H14.42ZM9 12C8.45 12 8 12.45 8 13C8 13.55 8.45 14 9 14C9.55 14 10 13.55 10 13C10 12.45 9.55 12 9 12ZM15 12C14.45 12 14 12.45 14 13C14 13.55 14.45 14 15 14C15.55 14 16 13.55 16 13C16 12.45 15.55 12 15 12ZM21 5V11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5L12 1L21 5ZM18 12L15.84 7C15.64 6.42 15.08 6 14.42 6H9.42C8.76 6 8.2 6.42 8 7L6 12V16C6 16.55 6.45 17 7 17H8C8.55 17 9 16.55 9 16V15H15V16C15 16.55 15.45 17 16 17H17C17.55 17 18 16.55 18 16V12Z"
                fill={getTextColor(insuranceStatus)}
              />
            </svg>

            <p>Third Party Insurance</p>
          </div>
          <div>
            <p>{userData?.insurance[0]?.expiry_date}</p>
            <p>Expiry date</p>
          </div>
        </div>
      </div>

      <div className={styles.papers_card}>
        <div className={styles.papers_card_upper}>
          <div>
            <p>Roadworthiness</p>
          </div>
          <div>
            <p style={{ color: getTextColor(roadWorthinessStatus) }}>
              {splitStringAtPunctuation(roadWorthinessStatus)}
            </p>
          </div>
        </div>
        <div
          className={styles.papers_card_lower}
          style={{ backgroundColor: getColorCode(roadWorthinessStatus) }}
        >
          <div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.42 7.5L16 11H8L9.42 7.5H14.42ZM9 12C8.45 12 8 12.45 8 13C8 13.55 8.45 14 9 14C9.55 14 10 13.55 10 13C10 12.45 9.55 12 9 12ZM15 12C14.45 12 14 12.45 14 13C14 13.55 14.45 14 15 14C15.55 14 16 13.55 16 13C16 12.45 15.55 12 15 12ZM21 5V11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5L12 1L21 5ZM18 12L15.84 7C15.64 6.42 15.08 6 14.42 6H9.42C8.76 6 8.2 6.42 8 7L6 12V16C6 16.55 6.45 17 7 17H8C8.55 17 9 16.55 9 16V15H15V16C15 16.55 15.45 17 16 17H17C17.55 17 18 16.55 18 16V12Z"
                fill={getTextColor(roadWorthinessStatus)}
              />
            </svg>

            <p>Roadworthiness</p>
          </div>
          <div>
            <p>{userData?.road[0]?.expiry_date}</p>
            <p>Expiry date</p>
          </div>
        </div>
      </div>

      {userData?.fine !== "No Record Found" && (
        <div className={styles.papers_card}>
          <div className={styles.papers_card_upper}>
            <div>
              <p>Fines</p>
            </div>
            <div>
              <p style={{ color: getTextColor(roadWorthinessStatus) }}>
                ₦{Number(userData?.fine[0]?.amount).toLocaleString()}
              </p>
            </div>
          </div>
          <div
            className={styles.papers_card_lower}
            style={{ backgroundColor: getColorCode(roadWorthinessStatus) }}
          >
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.42 7.5L16 11H8L9.42 7.5H14.42ZM9 12C8.45 12 8 12.45 8 13C8 13.55 8.45 14 9 14C9.55 14 10 13.55 10 13C10 12.45 9.55 12 9 12ZM15 12C14.45 12 14 12.45 14 13C14 13.55 14.45 14 15 14C15.55 14 16 13.55 16 13C16 12.45 15.55 12 15 12ZM21 5V11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5L12 1L21 5ZM18 12L15.84 7C15.64 6.42 15.08 6 14.42 6H9.42C8.76 6 8.2 6.42 8 7L6 12V16C6 16.55 6.45 17 7 17H8C8.55 17 9 16.55 9 16V15H15V16C15 16.55 15.45 17 16 17H17C17.55 17 18 16.55 18 16V12Z"
                  fill={getTextColor(roadWorthinessStatus)}
                />
              </svg>

              <p>Fines</p>
            </div>
            <div>
              <p>{userData?.fine[0]?.created_at}</p>
              <p>Fined Date</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Papers;
