import React, { useEffect, useState } from "react";
import styles from "./AllCars.module.css";
import { Link } from "react-router-dom";
import UserApi from "../../utils/api/UserApi";
import Images from "../../utils/CarImages";

const AllCars = (props) => {
  const [vehicles, setVehicles] = useState(null);
  const [vehicleArr, setVehicleArr] = useState([]);

  useEffect(() => {
    const item = localStorage.getItem("userData");

    const parsedItem = JSON.parse(item);

    const vehicleArray = parsedItem?.userData?.vehicles;
    setVehicleArr(vehicleArray);
  }, []);

  useEffect(() => {
    // Function to fetch all vehicles from thier plate numbers
    const fetchVehicles = async () => {
      // Fetch all vehicle data at once
      const vehicles_plate_no = await Promise?.all(
        vehicleArr?.map(async (data) => {
          try {
            const res = await UserApi.getPaperData({
              plate_no: data.plate_no,
            });
            return res.data?.insurance[0];
          } catch (err) {
            console.log(err.response);
            return null;
          }
        })
      );
      setVehicles(vehicles_plate_no);
    };
    fetchVehicles();
  }, [vehicleArr]);
  return (
    <div className={styles.body}>
      <div className={styles.heading}>
        <p>Vehicles</p>
      </div>
      <Link to={`/home/${props.number}`}>Go Back</Link>
      <div className={styles.all_vehicles}>
        {vehicles?.map((cars, index) => {
          // Get images of all vehicles from the array
          const carImage = Images?.find(
            (data) =>
              data.name?.toUpperCase() ===
              `${
                cars?.year
              }-${cars?.make?.toUpperCase()}-${cars?.model?.toUpperCase()}`
          );
          return (
            <div className={styles.vehicle_card}>
              <div className={styles.vehicleList_wrapper_inner}>
                <div className={styles.vehicleList_wrapper_inner_image}>
                  <img src={carImage.image} alt="dongle" />
                </div>
              </div>

              <div className={styles.vehicle_text}>
                <h3>{cars.plate_no}</h3>
                <h4>
                  {cars?.year} {cars?.make?.toUpperCase()}{" "}
                  {cars?.model?.toUpperCase()}
                </h4>
                <p>{cars?.make?.toUpperCase()}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllCars;
